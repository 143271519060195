<template lang="pug">
.group
	.flex.flex--jcsb.flex--aic.mb-3(v-if="title || count || options")
		.flex
			.subtitle(v-if="title") {{ title }}
			component.ml-4(v-if="icon" :is="`icon-${icon}`" @click="disabled ? null : $emit('onClick')" color="white" fill="red" circle :disabled="disabled")
		b(v-if="subtitle") {{ subtitle }}
		div
			b(v-if="count") {{ count }}
		BaseSelect.group__select.ml-4(
			v-if="options"
			placeholder="Сортировка"
			:options="options"
			@update:option="$emit('onSort', $event)"
		)
	.grid(:class="grid ? `grid--${grid}` : null")
		slot
</template>

<script>
import IconPlus from './icons/IconPlus'
import BaseSelect from './BaseSelect'

export default {
	name: 'BaseGroup',
	components: { BaseSelect, IconPlus },
	props: {
		title: {
			type: [String, null],
			default: null
		},
		subtitle: {
			type: [String, null],
			default: null
		},
		count: {
			type: [Number, null],
			default: null
		},
		grid: {
			type: [Number, null],
			default: null
		},
		icon: {
			type: [String, null],
			default: null
		},
		options: {
			type: [Object, null],
			default: null
		},
		disabled: {
			type: [Boolean],
			default: false
		}
	},
	emits: ['onClick', 'onSort']
}
</script>

<style lang="scss" scoped>
.group {
	padding: 16px;
	border: 1px solid $borderPrimary;
	border-radius: $radiusPrimary;
	box-shadow: $shadowPrimary;

	b {
		text-align: right;
		position: relative;
		color: $colorInverse;

		&::before {
			content: '';
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 24px;
			height: 24px;
			background-color: $bgMain;
			border-radius: 50%;
		}
	}

	@include for-tablet {
		&__select {
			width: 200px;
		}
	}
}
</style>
